// DateRangeFilter.tsx
import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

export interface TimeRange {
  startDate?: string;
  endDate?: string;
}

interface DateRangeFilterProps {
  timeRange: TimeRange;
  onTimeRangeChange: (timeRange: TimeRange) => void;
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  timeRange,
  onTimeRangeChange,
}) => {
  const handleDateRangeChange = (dates: any) => {
    if (dates && dates.length === 2) {
      onTimeRangeChange({
        startDate: dates[0].format('YYYY-MM-DD'),
        endDate: dates[1].format('YYYY-MM-DD')
      });
    } else {
      onTimeRangeChange({});
    }
  };

  const handleClear = () => {
    onTimeRangeChange({});
  };

  // 将字符串日期转换为 dayjs 对象
  const currentDates = timeRange.startDate && timeRange.endDate 
    ? [
        dayjs(timeRange.startDate),
        dayjs(timeRange.endDate)
      ] as [Dayjs, Dayjs]
    : null;

  return (
    <div className="bg-white rounded-lg p-4 shadow mb-6">
      <div className="flex items-center gap-4">
        <label className="text-sm text-gray-600">Filter by Date Range:</label>
        <DatePicker.RangePicker 
          onChange={handleDateRangeChange}
          format="YYYY-MM-DD"
          value={currentDates}
        />
        {timeRange.startDate && timeRange.endDate && (
          <button 
            onClick={handleClear}
            className="text-sm text-red-600 hover:text-red-800"
          >
            Clear
          </button>
        )}
      </div>
    </div>
  );
};

export default DateRangeFilter;