import React, { useEffect, useState } from 'react';

interface Event {
  startTime: string;
}

interface HeatMapData {
  [day: string]: {
    [hour: string]: number;
  };
}

interface Props {
  timeRange: { startDate?: string; endDate?: string };
}

const HeatMapChart: React.FC<Props> = ({ timeRange }) => {
  const [heatMapData, setHeatMapData] = useState<HeatMapData>({});
  const [loading, setLoading] = useState<boolean>(true);

  const timeSlots = Array.from({ length: 24 }, (_, i) => `${i}:00 - ${i + 1}:00`);
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const params = new URLSearchParams();

        if (timeRange.startDate) {
          params.append('startTime', `${timeRange.startDate}T00:00:00.000Z`);
        }
        if (timeRange.endDate) {
          params.append('endTime', `${timeRange.endDate}T23:59:59.999Z`);
        }
        params.append('limit', '1000');

        const response = await fetch(`https://api.backend.knowwhatson.com/api/events?${params.toString()}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch events data: ${response.status}`);
        }

        const { data }: { data: Event[] } = await response.json();

        const groupedData: HeatMapData = {};
        days.forEach(day => (groupedData[day] = {}));

        data.forEach((event: Event) => {
          const eventDate = new Date(event.startTime);
          const day = days[eventDate.getDay()];
          const hour = eventDate.getHours();
          if (!groupedData[day][hour]) {
            groupedData[day][hour] = 0;
          }
          groupedData[day][hour] += 1;
        });

        setHeatMapData(groupedData);
      } catch (error) {
        console.error('Failed to fetch events data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [timeRange]);

  const getIntensityClass = (count: number) => {
    if (!count) return 'bg-gray-100';
    if (count <= 5) return 'bg-blue-50';
    if (count <= 10) return 'bg-blue-200';
    if (count <= 20) return 'bg-blue-400';
    return 'bg-blue-600';
  };

  return (
    <div className="bg-white rounded-lg p-4 shadow">
      <h2 className="text-lg font-semibold mb-4">Event Time Distribution</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="overflow-x-auto">
          <div className="min-w-[700px]">
            <div className="grid grid-cols-[100px_1fr] gap-1">
              <div className="text-sm"></div>
              <div className="grid grid-cols-7 gap-1">
                {days.map(day => (
                  <div key={day} className="text-sm text-center pb-2">{day}</div>
                ))}
              </div>
            </div>
            {timeSlots.map((time, hour) => (
              <div key={time} className="grid grid-cols-[90px_1fr] gap-3">
                <div className="text-sm whitespace-nowrap pr-2 flex items-center">{time}</div>
                <div className="grid grid-cols-7 gap-1">
                  {days.map(day => {
                    const count = heatMapData[day]?.[hour] || 0;
                    return (
                      <div
                        key={`${day}-${hour}`}
                        className={`h-8 border border-gray-200 ${getIntensityClass(count)} hover:bg-blue-100 transition-colors`}
                        title={`${count} events`}
                      />
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default HeatMapChart;
