import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { sidebarItems } from "../data/SidebarItems";

const { Sider } = Layout;

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    // 根据当前路径动态设置选中项
    const matchingItem = sidebarItems.find((item) =>
      item.link === location.pathname ||
      item.children?.some((subItem) => subItem.link === location.pathname)
    );

    if (matchingItem) {
      const parentKey = String(sidebarItems.indexOf(matchingItem) + 1);

      if (matchingItem.children) {
        const childKey = matchingItem.children.findIndex(
          (subItem) => subItem.link === location.pathname
        );
        setSelectedKeys([`${parentKey}-${childKey + 1}`]);
      } else {
        setSelectedKeys([parentKey]);
      }
    }
  }, [location.pathname]);

  // Convert sidebar items to antd menu items format
  const getMenuItems = (): MenuProps["items"] => {
    return sidebarItems.map((item, index) => {
      if (item.children) {
        return {
          key: String(index + 1),
          icon: item.icon,
          label: item.name,
          children: item.children.map((subItem, subIndex) => ({
            key: `${index + 1}-${subIndex + 1}`,
            label: subItem.name,
            onClick: () => navigate(subItem.link),
          })),
        };
      }

      return {
        key: String(index + 1),
        icon: item.icon,
        label: item.name,
        onClick: () => navigate(item.link),
      };
    });
  };

  return (
    <Sider className="bg-white outline-slate-50 outline-1 text-gray-800">
      <Menu
        mode="inline"
        selectedKeys={selectedKeys} // 动态设置选中项
        className="bg-white"
        theme="light"
        items={getMenuItems()}
      />
    </Sider>
  );
};

export default Sidebar;
