// FacultyDisPiechart.tsx
import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

interface TimeRange {
  startDate?: string;
  endDate?: string;
}

interface FacultyEventData {
  name: string;
  count: number;
}

interface Event {
  id: string;
  name: string;
  facultyId: string;
  startTime: string;
}

interface EventResponse {
  data: Event[];
  pagination: {
    total: number;
    pages: number;
    current: number;
    hasNext: boolean;
    hasPrev: boolean;
  };
}

interface LookupResponse {
  faculties: Array<{
    id: string;
    name: string;
  }>;
}

const COLORS = ['#4e79a7', '#45b7cd', '#f58518', '#e45756', '#72b447'];

interface Props {
  timeRange: TimeRange;
}

const FacultyDisPiechart: React.FC<Props> = ({ timeRange }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<FacultyEventData[]>([]);
  const [total, setTotal] = useState(0);
  const [facultyMap, setFacultyMap] = useState<Map<string, string>>(new Map());

  // Fetch faculty lookup data
  useEffect(() => {
    const fetchFaculties = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://api.backend.knowwhatson.com/api/lookup-data', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch faculty data');
        }

        const result = await response.json() as LookupResponse;
        const map = new Map<string, string>();
        result.faculties.forEach(faculty => {
          map.set(faculty.id, faculty.name);
        });
        setFacultyMap(map);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load faculty data');
      }
    };

    fetchFaculties();
  }, []);

  // Fetch events data
  useEffect(() => {
    const fetchEvents = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            
            let url = 'https://api.backend.knowwhatson.com/api/events';
            const params = new URLSearchParams();
            
            // 前端设置的日期范围用来筛选事件的开始时间
            if (timeRange.startDate) {
              params.append('startTime', `${timeRange.startDate}T00:00:00.000Z`);
            }
            if (timeRange.endDate) {
              params.append('endTime', `${timeRange.endDate}T23:59:59.999Z`);
            }
            params.append('limit', '1000');
        
            const finalUrl = `${url}?${params.toString()}`;
            console.log('Fetching events filtered by start time:', finalUrl);
        
        
            const response = await fetch(finalUrl, {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });

        if (!response.ok) {
          throw new Error(`Request failed: ${response.status}`);
        }

        const result = await response.json() as EventResponse;
        console.log('Events response:', result);

        // Group events by faculty and count
        const facultyCounts = result.data.reduce<Record<string, FacultyEventData>>((acc, event) => {
          const facultyName = facultyMap.get(event.facultyId) || 'Unknown Faculty';
          
          if (!acc[facultyName]) {
            acc[facultyName] = {
              name: facultyName,
              count: 0
            };
          }
          acc[facultyName].count++;
          return acc;
        }, {});

        // Convert to array and sort by count
        const sortedData = Object.values(facultyCounts).sort((a, b) => b.count - a.count);
        setData(sortedData);
        setTotal(result.data.length);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError(err instanceof Error ? err.message : 'Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    if (facultyMap.size > 0) {
      fetchEvents();
    }
  }, [timeRange, facultyMap]);

  if (loading) {
    return (
      <div className="h-[400px] bg-white rounded-lg p-4 shadow flex items-center justify-center">
        <div className="text-gray-600">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-[400px] bg-white rounded-lg p-4 shadow flex items-center justify-center">
        <div className="text-red-600">
          <p>{error}</p>
          <button 
            onClick={() => window.location.reload()}
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  const CustomTooltip = ({ active, payload }: {
    active?: boolean;
    payload?: Array<{ payload: FacultyEventData }>;
  }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white p-3 border border-gray-200 shadow-lg rounded">
          <p className="font-medium mb-1">{data.name}</p>
          <p className="text-sm text-gray-600">
            Events: {data.count.toLocaleString()}
          </p>
          <p className="text-sm text-gray-600">
            Percentage: {((data.count / total) * 100).toFixed(1)}%
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="bg-white rounded-lg p-4 shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Events by Faculty</h2>
        <span className="text-sm text-gray-600">
          Total Events: {total}
        </span>
      </div>

      <div className="flex justify-center mb-6">
        <PieChart width={300} height={350}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={80}
            dataKey="count"
            nameKey="name"
            label={({ percent }) => `${(percent * 100).toFixed(1)}%`}
          >
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend 
            layout="horizontal"
            align="center"
            verticalAlign="bottom"
            iconSize={8}
            formatter={(value: string) => {
              return value.length > 35 ? `${value.substring(0, 15)}...` : value;
            }}
            wrapperStyle={{
              paddingTop: '20px',
              width: '100%',
              fontSize: '14px',
              lineHeight: '20px'
            }}
          />
        </PieChart>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="text-gray-600 text-sm border-b border-gray-200">
              <th className="text-left py-2 font-medium">Faculty</th>
              <th className="text-right py-2 font-medium">Events</th>
              <th className="text-right py-2 font-medium">Percentage</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {data.map((item, index) => (
              <tr 
                key={index} 
                className="border-b border-gray-100 hover:bg-gray-50 transition-colors"
              >
                <td className="py-2">{item.name}</td>
                <td className="text-right">{item.count.toLocaleString()}</td>
                <td className="text-right">
                  {((item.count / total) * 100).toFixed(1)}%
                </td>
              </tr>
            ))}
            <tr className="font-medium bg-gray-50">
              <td className="py-2">Total</td>
              <td className="text-right">{total.toLocaleString()}</td>
              <td className="text-right">100.0%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FacultyDisPiechart;