import React, { useState } from 'react';
import FacultyDisPiechart from '../components/FacultyDisPiechart';
import HeatMapChart from '../components/HeatMapChart';
import EventsTrendLinechart from '../components/EventsTrendLinechart';
import OrganizersLocationTable from '../components/OrganizersLocationTable';
import DateRangeFilter, { TimeRange } from '../components/DateRangeFilter';

const EventsDashboardPage: React.FC = () => {
  const [timeRange, setTimeRange] = useState<TimeRange>({});

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      {/* Date Range Filter */}
      <div className="mb-6">
        <DateRangeFilter timeRange={timeRange} onTimeRangeChange={setTimeRange} />
      </div>

      {/* Faculty Distribution Chart and Heatmap Chart */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6">
        {/* Pie Chart (1/3 width) */}
        <div className="lg:col-span-1 bg-white rounded-lg p-4 shadow">
          <FacultyDisPiechart timeRange={timeRange} />
        </div>
        {/* Heat Map (2/3 width) */}
        <div className="lg:col-span-2 bg-white rounded-lg p-4 shadow">
          <HeatMapChart timeRange={timeRange} />
        </div>
      </div>

      {/* Events Trend Linechart */}
      <div className="mb-6 bg-white rounded-lg p-4 shadow">
        <EventsTrendLinechart timeRange={timeRange} />
      </div>

      {/* Organizers and Location Tables */}
      <div className="bg-white rounded-lg p-4 shadow">
        <OrganizersLocationTable timeRange={timeRange} />
      </div>
    </div>
  );
};

export default EventsDashboardPage;
