import { message, Spin, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import apiClient from '../utils/axios';

interface Event {
  id: string;
  name: string;
  location: string;
  startTime: string;
  endTime: string;
  organizer: string;
}

const EventsDataPage: React.FC = () => {
  const [data, setData] = useState<Event[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const columns = [
    {
      title: 'Event Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (text: string) => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
      key: 'endTime',
      render: (text: string) => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Organizer',
      dataIndex: 'organizer',
      key: 'organizer',
    },
  ];

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const { data } = await apiClient.get<Event[]>('/api/events');
        console.log('API Response:', data); 
        const events = Array.isArray(data) ? data : [];
        const formattedEvents = events.map((event) => ({
          ...event,
          key: event.id,
        }));
        setData(formattedEvents);
      } catch (error: any) {
        console.error('Error fetching events:', error);
        message.error(
          error.response?.data?.message || 'Failed to fetch events'
        );
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        scroll={{ x: 'max-content' }}

        // pagination={{ defaultPageSize: 10 }}
        // onRow={(record) => ({
        //   onClick: () => console.log('Row data:', record)
        // })}
      />
    </div>
  );
};

export default EventsDataPage;
