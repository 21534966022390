import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { message, Spin, Table } from 'antd';
import apiClient from '../utils/axios';
import UserDistributionMap from '../components/UserDistributionMap';
import DegreeDisPiechart from '../components/DegreeDisPiechart';
import GraYearPiechart from '../components/GraYearPiechart';
import DateRangeFilter, { TimeRange } from '../components/DateRangeFilter';

interface User {
  id: string;
  email: string;
  name: string;
  role: string;
  citizenship?: string;
  facultyId?: string;
  degreeId?: string;
  isArcMember: boolean;
  graduationYear?: number;
  createdAt: string;
}

const UsersDataPage: React.FC = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [timeRange, setTimeRange] = useState<TimeRange>({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 250,
      ellipsis: true,
      fixed: 'left' as const,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      ellipsis: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Citizenship',
      dataIndex: 'citizenship',
      key: 'citizenship',
      width: 150,
      ellipsis: true,
    },
    {
      title: 'Graduation Year',
      dataIndex: 'graduationYear',
      key: 'graduationYear',
      width: 150,
      ellipsis: true,
      render: (text: number) => text || 'N/A',
    },
  ];

  const fetchUsers = async (page = 1, pageSize = 10) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        message.error('Please login first');
        navigate('/login');
        return;
      }

      setLoading(true);

      const params = new URLSearchParams({
        page: page.toString(),
        limit: pageSize.toString()
      });

      if (timeRange.startDate) {
        params.append('startDate', timeRange.startDate);
      }
      if (timeRange.endDate) {
        params.append('endDate', timeRange.endDate);
      }

      const response = await apiClient.get<{
        users: User[];
        pagination: { total: number; pages: number; current: number };
      }>(`api/users?${params.toString()}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const { users, pagination: pagData } = response.data;
      setData(users.map((user) => ({ ...user, key: user.id })));
      setPagination({
        current: pagData.current,
        pageSize,
        total: pagData.total,
      });
    } catch (error: any) {
      if (!error.response) {
        message.error('Network error. Please check your connection.');
      } else if (error.response.status === 401) {
        message.error('Session expired. Please login again.');
        navigate('/login');
      } else {
        message.error(error.response.data?.message || 'Failed to fetch users.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(pagination.current, pagination.pageSize);
  }, []);

  useEffect(() => {
    fetchUsers(pagination.current, pagination.pageSize);
  }, [timeRange]);

  const handleTableChange = (paginationInfo: any) => {
    fetchUsers(paginationInfo.current, paginationInfo.pageSize);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="space-y-6 p-6">
      <DateRangeFilter
        timeRange={timeRange}
        onTimeRangeChange={setTimeRange}
      />

      <div>
        <UserDistributionMap timeRange={timeRange} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <DegreeDisPiechart />
        <GraYearPiechart />
      </div>

      <div className="bg-white rounded-lg p-4 shadow">
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
            showSizeChanger: true,
            onChange: (page, pageSize) => fetchUsers(page, pageSize),
          }}
          onChange={handleTableChange}
          rowKey="id"
          scroll={{ x: 900 }}
        />
      </div>
    </div>
  );
};

export default UsersDataPage;
