import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Modal, Row, Table, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import apiClient from '../utils/axios';

interface User {
  id: string;
  username: string;
  email: string;
  role: 'SUPER_ADMIN' | 'ADMIN' | 'EXTERNAL' | 'ORGANIZER';
}

interface UsersResponse {
  users: User[];
  pagination: {
    total: number;
    pages: number;
    current: number;
    hasNext: boolean;
    hasPrev: boolean;
  };
}

interface AdminUserPayload {
  username: string;
  email: string;
  password?: string; // Optional for updates
  role: 'SUPER_ADMIN' | 'ADMIN' | 'EXTERNAL' | 'ORGANIZER';
}

const ManageUsers: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [search, setSearch] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 20,
  });
  const [userRole, setUserRole] = useState<'SUPER_ADMIN' | 'ADMIN' | 'EXTERNAL' | 'ORGANIZER' | ''>('');
  const [userId, setUserId] = useState<string>(''); // Store user ID if needed

  // Extract userRole and userId from JWT token
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const payload = JSON.parse(atob(token.split('.')[1]));
        setUserRole(payload.role);
        setUserId(payload.id); // Ensure the token contains the user ID
      } catch (error) {
        console.error('Error parsing token:', error);
        message.error('Invalid authentication token. Please log in again.');
      }
    } else {
      message.error('No authentication token found. Please log in.');
    }
  }, []);

  // Fetch users when dependencies change
  useEffect(() => {
    if (['SUPER_ADMIN', 'ADMIN'].includes(userRole)) {
      fetchUsers();
    }
  }, [pagination.current, pagination.pageSize, search, userRole]);

  const fetchUsers = async (): Promise<void> => {
    try {
      const params: any = {
        page: pagination.current,
        limit: pagination.pageSize,
      };

      if (search.trim()) {
        params.user_name = search.trim();
      }

      const { data } = await apiClient.get<UsersResponse>('/api/admin-users', { params });
      console.log('Fetched Users:', data);

      setPagination((prev) => ({
        ...prev,
        total: data.pagination.total,
        current: data.pagination.current,
      }));

      const formattedUsers = data.users.map((user) => ({
        ...user,
        key: user.id,
      }));

      setUsers(formattedUsers);
      setFilteredUsers(formattedUsers);
    } catch (error: any) {
      console.error('Error fetching users:', error);
      if (error.response?.status === 403) {
        message.error('You do not have permission to view admin users.');
      } else {
        message.error(error.response?.data?.message || 'Failed to fetch users.');
      }
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
    setPagination((prev) => ({ ...prev, current: 1 })); // Reset to first page on search
  };

  const handleDelete = async (id: string): Promise<void> => {
    try {
      await apiClient.delete(`/api/admin-users/${id}`);
      message.success('User deleted successfully.');
      fetchUsers();
    } catch (error: any) {
      console.error('Error deleting user:', error);
      if (error.response?.status === 404) {
        message.error('User not found.');
      } else if (error.response?.status === 403) {
        message.error('You do not have permission to delete this user.');
      } else {
        message.error(error.response?.data?.message || 'Failed to delete the user.');
      }
    }
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      const values = await form.validateFields();

      // Construct payload
      const payload: AdminUserPayload = {
        username: values.username,
        email: values.email,
        role: values.role,
      };

      // Include password only if creating a new user
      if (!selectedUser && values.password) {
        payload.password = values.password;
      }

      // Additional validation for user_id if introduced in future
      // if (payload.user_id && !/^\d+$/.test(payload.user_id)) {
      //   message.error('user_id must contain only digits.');
      //   return;
      // }

      if (selectedUser) {
        await apiClient.put(`/api/admin-users/${selectedUser.id}`, payload);
        message.success('User updated successfully.');
      } else {
        await apiClient.post('/api/admin-users', payload);
        message.success('User created successfully.');
      }

      setIsModalOpen(false);
      form.resetFields();
      fetchUsers();
    } catch (error: any) {
      console.error('Error submitting form:', error);
      if (error.response?.data?.errors) {
        // Display detailed validation errors
        error.response.data.errors.forEach((err: any) => {
          message.error(`${err.param}: ${err.msg}`);
        });
      } else if (error.response?.data?.message) {
        message.error(error.response.data.message);
      } else {
        message.error('Failed to save the user.');
      }
    }
  };

  const handleAdd = (): void => {
    setSelectedUser(null);
    form.resetFields();
    setIsModalOpen(true);
  };

  const handleEdit = (user: User): void => {
    setSelectedUser(user);
    form.setFieldsValue({
      username: user.username,
      email: user.email,
      role: user.role,
    });
    setIsModalOpen(true);
  };

  const columns = [
    { title: 'Username', dataIndex: 'username', key: 'username', width: 200 },
    { title: 'Email', dataIndex: 'email', key: 'email', width: 300 },
    { title: 'Role', dataIndex: 'role', key: 'role', width: 150 },
    {
      title: 'Actions',
      key: 'actions',
      width: 200,
      render: (record: User) => (
        <>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            style={{ marginRight: '8px', width: '80px' }}
          >
            Edit
          </Button>
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.id)}
            style={{ width: '80px' }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div>
      {/* Search Bar and Action Buttons */}
      <Row style={{ marginBottom: '1rem' }} align="middle">
        <Col>
          <Input
            placeholder="Search Users by Username"
            value={search}
            onChange={handleSearchChange}
            style={{ width: '300px', marginRight: '1rem' }}
            allowClear
          />
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={fetchUsers}
            style={{ marginRight: '1rem' }}
          >
            Search
          </Button>
          <Button type="primary" onClick={handleAdd}>
            Add User
          </Button>
        </Col>
      </Row>

      {/* Users Table */}
      <Table
        columns={columns}
        dataSource={filteredUsers}
        rowKey="id"
        style={{ marginTop: '1rem' }}
        scroll={{ x: 'max-content' }}
        pagination={{
          total: pagination.total,
          current: pagination.current,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '50'],
          onChange: (page, pageSize) => {
            setPagination((prev) => ({ ...prev, current: page, pageSize: pageSize || prev.pageSize }));
          },
          onShowSizeChange: (current, size) => {
            setPagination((prev) => ({ ...prev, current, pageSize: size }));
          },
        }}
        locale={{
          emptyText: 'No users found.',
        }}
      />

      {/* Modal for Add/Edit User */}
      <Modal
        title={selectedUser ? 'Edit User' : 'Add User'}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={handleSubmit}
        okText={selectedUser ? 'Update' : 'Create'}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="username"
            label="Username"
            rules={[{ required: true, message: 'Please enter the username.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Please enter the email.' },
              { type: 'email', message: 'Please enter a valid email.' },
            ]}
          >
            <Input />
          </Form.Item>
          {!selectedUser && (
            <Form.Item
              name="password"
              label="Password"
              rules={[
                { required: true, message: 'Please enter the password.' },
                { min: 8, message: 'Password must be at least 8 characters.' },
              ]}
            >
              <Input.Password />
            </Form.Item>
          )}
          <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true, message: 'Please select a role.' }]}
          >
            <Select>
              <Select.Option value="SUPER_ADMIN">Super Admin</Select.Option>
              <Select.Option value="ADMIN">Admin</Select.Option>
              <Select.Option value="EXTERNAL">External</Select.Option>
              <Select.Option value="ORGANIZER">Organizer</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ManageUsers;
