import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import CreateEventsPage from "./pages/CreateEventsPage";
import EventsDashboardPage from "./pages/EventDashboardPage";
import EventsDataPage from "./pages/EventsDataPage";
import LocationsDataPage from "./pages/LocationsDataPage";
import UsersDataPage from "./pages/UsersDataPage";
// import AdminDataPage from "./pages/AdminDataPage";
import AdminEventsPage from "./pages/AdminEventsPage";
import AdminUsersPage from "./pages/AdminUsersPage";
import LoginPage from "./pages/LoginPage";

// 检查是否登录
const isAuthenticated = () => {
  return !!localStorage.getItem("token"); // 如果 token 存在，返回 true；否则返回 false
};

// 路由守卫组件
const PrivateRoute: React.FC<{ element: React.ReactNode }> = ({ element }) => {
  return isAuthenticated() ? (
    <>{element}</>
  ) : (
    <Navigate to="/login" replace />
  );
};

const App: React.FC = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";

  return (
    <div className="flex flex-col h-screen">
      {/* 如果不是登录页面，显示 Header */}
      {!isLoginPage && <Header />}
      <div className="flex flex-1">
        {/* 如果不是登录页面，显示 Sidebar */}
        {!isLoginPage && <Sidebar />}
        <main className="flex-1 overflow-y-auto">
          <div
            className={
              isLoginPage
                ? "h-screen flex justify-center items-center"
                : "h-[95vh]"
            }
          >
            <div
              className={
                isLoginPage
                  ? ""
                  : "h-full overflow-y-auto scrollbar-hide bg-white border border-gray-200 rounded-lg shadow-inner p-6"
              }
            >
              <Routes>
                {/* 登录页面 */}
                <Route path="/login" element={<LoginPage />} />

                {/* 受保护的路由 */}
                <Route
                  path="/users"
                  element={<PrivateRoute element={<UsersDataPage />} />}
                />
                <Route
                  path="/events"
                  element={<PrivateRoute element={<EventsDataPage />} />}
                />
                <Route
                  path="/events/dashboard"
                  element={<PrivateRoute element={<EventsDashboardPage />} />}
                />
                <Route
                  path="/events/create"
                  element={<PrivateRoute element={<CreateEventsPage />} />}
                />
                <Route
                  path="/events/:id"
                  element={<PrivateRoute element={<CreateEventsPage />} />}
                />
                <Route
                  path="/locations"
                  element={<PrivateRoute element={<LocationsDataPage />} />}
                />
                {/* <Route path="/admin" element={<AdminDataPage />} /> */}
                <Route
                  path="/admin/events"
                  element={<PrivateRoute element={<AdminEventsPage />} />}
                />
                <Route
                  path="/admin/users"
                  element={<PrivateRoute element={<AdminUsersPage />} />}
                />
              </Routes>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default App;
