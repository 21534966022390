import { EnvironmentOutlined, UserOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Select,
  Spin,
  TimePicker,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import apiClient from '../utils/axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';


const { Option } = Select;

interface Event {
  name: string;
  summary: string;
  location?: string;
  externalLink?: string;
  collectRegistration?: boolean;
  startTime?: string;
  endTime?: string;
  description?: string;
  tags?: string;
  organizer?: string;
  facultyId?: string;
  clubTypeId?: string;
}

export function CreateEventsPage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <Spin size="large" />
      </div>
    );
  }

  const onFinish = async (values: any) => {
    try {
      const formattedValues: Partial<Event> = {
        name: values.eventName,
        summary: values.summary,
        location: values.location,
        externalLink: values.externalLink,
        organizer: values.organizer,
        ...(values.eventDate && values.startTime && {
          startTime: `${values.eventDate.format('YYYY-MM-DD')}T${values.startTime.format('HH:mm:ss')}`
        }),
        ...(values.eventDate && values.endTime && {
          endTime: `${values.eventDate.format('YYYY-MM-DD')}T${values.endTime.format('HH:mm:ss')}`
        }),
        facultyId: values.facultyId,
        clubTypeId: values.clubTypeId,
        tags: values.tags,
        description: values.description,
        collectRegistration: values.collectRegistration
      };

      await apiClient.post('/api/events', formattedValues);
      message.success('Event created successfully');
      navigate('/events');
    } catch (error: any) {
      console.error('Error creating event:', error);
      if (error.response?.status === 400) {
        const errorMessage =
          error.response?.data?.message ||
          Object.values(error.response?.data || {}).join(', ') ||
          'Invalid event data. Please check your inputs.';
        message.error(errorMessage);
        return;
      }
      message.error('Failed to create event');
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish} className="max-w-[600px]">
      <Form.Item
        label="Event Name"
        name="eventName"
        rules={[
          { required: true, message: 'Please enter the event name' }, 
          { max: 1023, message: 'Name cannot exceed 1023 characters' }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Summary"
        name="summary"
        rules={[{ required: true, message: 'Please enter the event summary' }]}
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        label="Location"
        name="location"
        rules={[{ max: 255, message: 'Location cannot exceed 255 characters' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="External Link"
        name="externalLink"
        rules={[{ max: 1023, message: 'Link cannot exceed 1023 characters' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Event Date"
        name="eventDate"
      >
        <DatePicker style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        label="Start Time"
        name="startTime"
      >
        <TimePicker style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        label="End Time"
        name="endTime"
      >
        <TimePicker style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        label="Tags (comma-separated)"
        name="tags"
        rules={[{ max: 1023, message: 'Tags cannot exceed 1023 characters' }]}
      >
        <Input placeholder="e.g., academic,social,free-food" />
      </Form.Item>

      <Form.Item
        label="Organizer"
        name="organizer"
        rules={[{ max: 255, message: 'Organizer name cannot exceed 255 characters' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Faculty ID"
        name="facultyId"
        rules={[{ len: 36, message: 'Faculty ID must be 36 characters' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Club Type ID"
        name="clubTypeId"
        rules={[{ len: 36, message: 'Club Type ID must be 36 characters' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="collectRegistration" valuePropName="checked">
        <Checkbox>Collect event registration</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Create Event
        </Button>
      </Form.Item>
    </Form>
  );
}

export default CreateEventsPage;





