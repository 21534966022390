import { UserOutlined, CalendarOutlined, EnvironmentOutlined, PlusOutlined, TeamOutlined, AppstoreAddOutlined, ContactsOutlined } from '@ant-design/icons';
import { SidebarItem } from '../interfaces/types';

export const sidebarItems: SidebarItem[] = [
  { name: 'Users', icon: <UserOutlined />, link: '/users' },
  { 
    name: 'Events', 
    icon: <CalendarOutlined />, 
    link: '/events', 
    children: [
      { name: 'Dashboard', icon: <CalendarOutlined />, link: '/events/dashboard' },
      { name: 'Create Event', icon: <PlusOutlined />, link: '/events/create' }
    ]
  },
  { name: 'Locations', icon: <EnvironmentOutlined />, link: '/locations' },
  { 
    name: 'Admin', 
    icon: <TeamOutlined />,
    link: '/admin', 
    children: [
      { name: 'Manage Events', icon: <AppstoreAddOutlined />, link: '/admin/events' },
      { name: 'Manage Users', icon: <ContactsOutlined />, link: '/admin/users' }
    ]
  },
];
