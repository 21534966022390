import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem('token');

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleLogoutClick = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  return (
    <header className="flex justify-between items-center text-gray-800 border-b-2 h-[5vh] px-6">
      <h1 className="text-xl font-bold">What's On! Dashboard</h1>
      <Button
        type="primary"
        onClick={isLoggedIn ? handleLogoutClick : handleLoginClick}
        className="bg-blue-500 hover:bg-blue-600 text-white"
      >
        {isLoggedIn ? 'Logout' : 'Login'}
      </Button>
    </header>
  );
};

export default Header;
