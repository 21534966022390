import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import apiClient from '../utils/axios';

interface DemographicData {
  key: string;
  name: string;
  count: number;
}

interface DemographicResponse {
  type: string;
  total: number;
  data: DemographicData[];
}

const DegreeDisPiechart: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<DemographicData[]>([]);
  const [total, setTotal] = useState(0);

  const COLORS = ['#4e79a7', '#45b7cd', '#f58518', '#e45756', '#72b447'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await apiClient.get<DemographicResponse>(
          '/api/users/demographics?type=degree',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        
        setData(response.data.data.sort((a, b) => b.count - a.count));
        setTotal(response.data.total);
      } catch (err) {
        setError('Failed to load demographic data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="bg-white rounded-lg p-4 shadow flex justify-center items-center min-h-[400px]">
        <Spin />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg p-4 shadow flex justify-center items-center min-h-[400px]">
        <div className="text-red-600">{error}</div>
      </div>
    );
  }

  const chartWidth = 400;
  const chartHeight = 400;
  const pieRadius = 80;

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="bg-white p-2 border border-gray-200 shadow-lg rounded">
          <p className="font-medium mb-1">{data.name}</p>
          <p className="text-sm text-gray-600">
            Users: {data.count.toLocaleString()}
          </p>
          <p className="text-sm text-gray-600">
            Percentage: {((data.count / total) * 100).toFixed(1)}%
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="bg-white rounded-lg p-4 shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Degree Distribution</h2>
        <span className="text-sm text-gray-600">Total: {total}</span>
      </div>

      <div className="flex justify-center mb-6">
        <PieChart width={chartWidth} height={chartHeight}>
          <Pie
            data={data}
            cx={chartWidth / 2}
            cy={180}
            outerRadius={pieRadius}
            dataKey="count"
            nameKey="name"
            label={({ percent }) => `${(percent * 100).toFixed(1)}%`}
          >
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip 
            content={<CustomTooltip />}
            wrapperStyle={{ outline: 'none' }}
          />
          <Legend 
            layout="horizontal"
            align="center"
            verticalAlign="bottom"
            wrapperStyle={{
              paddingTop: '20px',
              width: '100%',
              fontSize: '14px',
            }}
            iconSize={8}
            formatter={(value: string) => {
              return value.length > 25 ? `${value.substring(0, 15)}...` : value;
            }}
            margin={{ top: 10, bottom: 0, left: 0, right: 0 }}
          />
        </PieChart>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="text-gray-600 text-sm border-b">
              <th className="text-left py-2">Degree</th>
              <th className="text-right">Users</th>
              <th className="text-right">Percentage</th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {data.map((item, index) => (
              <tr key={index} className="border-b hover:bg-gray-50">
                <td className="py-2">{item.name}</td>
                <td className="text-right">{item.count.toLocaleString()}</td>
                <td className="text-right">
                  {((item.count / total) * 100).toFixed(1)}%
                </td>
              </tr>
            ))}
            <tr className="font-medium bg-gray-50">
              <td className="py-2">Total</td>
              <td className="text-right">{total.toLocaleString()}</td>
              <td className="text-right">100.0%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DegreeDisPiechart;