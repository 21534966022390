import React from 'react';
import DataTable from '../components/DataTable'; // Adjust the path if necessary

const LocationsDataPage: React.FC = () => {
  const columns = [
    {
      title: 'Location Name',
      dataIndex: 'locationName',
      key: 'locationName',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
    },
    {
      title: 'Action',
      key: 'action'
    },
  ];

  const data = [
    {
      key: '1',
      locationName: 'Central Park',
      city: 'New York',
      country: 'USA',
    },
    {
      key: '2',
      locationName: 'Eiffel Tower',
      city: 'Paris',
      country: 'France',
    },
    {
      key: '3',
      locationName: 'Sydney Opera House',
      city: 'Sydney',
      country: 'Australia',
    },
    {
      key: '4',
      locationName: 'Colosseum',
      city: 'Rome',
      country: 'Italy',
    },
    {
      key: '5',
      locationName: 'Great Wall of China',
      city: 'Beijing',
      country: 'China',
    },
  ];

  return (
    <div>
      <DataTable columns={columns} data={data} />
    </div>
  );
};

export default LocationsDataPage;
