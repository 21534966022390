import { EnvironmentOutlined, UserOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Select,
  Spin,
  Table,
  Pagination,
  Popconfirm,
} from 'antd';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Event, Faculty } from '../interfaces/types';
import apiClient from '../utils/axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
// import { UserContext } from '../contexts/UserContext'; // Adjust the path as needed

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);

const { Option } = Select;

// Define the structure of the API response
interface EventsResponse {
  message: string;
  data: Event[];
  pagination: {
    page: number;
    limit: number;
    total: number;
  };
}

interface PaginationInfo {
  current: number;
  pageSize: number;
  total: number;
}

const AdminEventsPage = () => {
  const navigate = useNavigate();
  // const { user } = useContext(UserContext); // Assuming you have a UserContext
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<PaginationInfo>({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [filters, setFilters] = useState<{
    name?: string;
    startTime?: string;
    endTime?: string;
    location?: string;
    facultyId?: string;
    clubTypeId?: string;
  }>({});
  const [locations, setLocations] = useState<string[]>([]);
  const [filterType, setFilterType] = useState<string>('name'); // State for selected filter type

  // Modal state
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [form] = Form.useForm();

  // Add state variables for faculties and club types
  const [faculties, setFaculties] = useState<{ id: string; name: string }[]>([]);
  const [clubTypes, setClubTypes] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    fetchEvents();
    fetchFaculties();
    fetchClubTypes();
  }, [pagination.current, pagination.pageSize, filters]);

  const fetchEvents = async () => {
    setLoading(true);
    try {
      const params: any = {
        page: pagination.current,
        limit: pagination.pageSize,
        ...filters,
      };

      // if (user.role === 'Organizer') {
      //   params.creatorId = user.id;
      // } else if (user.role === 'External') {
      //   delete params.creatorId;
      // }

      if (filters.name) params.name = filters.name;
      if (filters.facultyId) params.facultyId = filters.facultyId;
      if (filters.clubTypeId) params.clubTypeId = filters.clubTypeId;
      if (filters.location) params.location = filters.location;
      if (filters.startTime) params.startTime = filters.startTime;
      if (filters.endTime) params.endTime = filters.endTime;

      console.log('Fetching events with params:', params);

      const response = await apiClient.get<EventsResponse>('/api/events', { params });
      let { data, pagination: pagInfo } = response.data;
     

      console.log('API Response Data:', data);
      console.log('Pagination Info:', pagInfo);

      setEvents(data);
      setPagination({
        current: pagInfo.page,
        pageSize: pagInfo.limit,
        total: pagInfo.total,
      });

      // Extract unique locations for dropdown
      const uniqueLocations: string[] = Array.from(
        new Set(
          data
            .map((event: Event) => event.location)
            .filter((loc: string | undefined): loc is string => typeof loc === 'string')
        )
      );
      setLocations(uniqueLocations);
    } catch (error: any) {
      console.error('Error fetching events:', error);
      message.error(error.response?.data?.message || 'Failed to fetch events');
    } finally {
      setLoading(false);
    }
  };

  const fetchFaculties = async () => {
    try {
      const response = await apiClient.get<Faculty[]>('/api/faculty');
      console.log('Faculties Data:', response.data); // Logging faculties
      setFaculties(response.data);
    } catch (error: any) {
      console.error('Error fetching faculties:', error);
      message.error('Failed to fetch faculties');
    }
  };

  const fetchClubTypes = async () => {
    try {
      const response = await apiClient.get('/api/club-type');
      setClubTypes(response.data);
    } catch (error: any) {
      console.error('Error fetching club types:', error);
      message.error('Failed to fetch club types');
    }
  };

  // Handler for filter type change
  const handleFilterTypeChange = (value: string) => {
    setFilterType(value);
  };

  // Handler for filter form submission
  const handleFilter = (values: any) => {
    const appliedFilters: any = {};
    if (values.name) appliedFilters.name = values.name;
    if (values.facultyId) appliedFilters.facultyId = values.facultyId;
    if (values.clubTypeId) appliedFilters.clubTypeId = values.clubTypeId;
    if (values.location) appliedFilters.location = values.location;
    if (values.startTime) appliedFilters.startTime = dayjs(values.startTime).toISOString();
    if (values.endTime) appliedFilters.endTime = dayjs(values.endTime).toISOString();

    console.log('Applying Filters:', appliedFilters); // Confirm filters
    setFilters(appliedFilters);
    setPagination({ ...pagination, current: 1 }); // Reset to first page on filter
  };

  // Handler for pagination change
  const handleTableChange = (page: number, pageSize?: number) => {
    setPagination({ current: page, pageSize: pageSize || pagination.pageSize, total: pagination.total });
  };

  // Delete event handler
  const handleDelete = async (id: string) => {
    try {
      await apiClient.delete(`/api/events/${id}`);
      message.success('Event deleted successfully');
      fetchEvents(); // Refresh the events list after deletion
    } catch (error: any) {
      console.error('Error deleting event:', error);
      message.error(error.response?.data?.message || 'Failed to delete event');
    }
  };

  // Modal Handlers
  const handleAdd = () => {
    setSelectedEvent(null);
    form.resetFields();
    setIsModalOpen(true);
  };

  const handleEdit = (event: Event) => {
    setSelectedEvent(event);
    form.setFieldsValue({
      ...event,
      startTime: event.startTime ? dayjs(event.startTime) : null,
      endTime: event.endTime ? dayjs(event.endTime) : null,
    });
    setIsModalOpen(true);
  };

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();

      // Process and sanitize form values
      const payload: any = {
        name: values.name,
        summary: values.summary,
        // Only include fields if they have valid values
        ...(values.location && { location: values.location }),
        ...(values.externalLink && { externalLink: values.externalLink }),
        collectRegistration: values.collectRegistration || false, // Ensure boolean
        ...(values.startTime && { startTime: dayjs(values.startTime).toISOString() }),
        ...(values.endTime && { endTime: dayjs(values.endTime).toISOString() }),
        ...(values.description && { description: values.description }),
        ...(values.tags && { tags: values.tags.split(',').map((tag: string) => tag.trim()) }), // Convert string to array
        ...(values.facultyId && { facultyId: values.facultyId }),
        ...(values.clubTypeId && { clubTypeId: values.clubTypeId }),
      };

      // Validate creatorId pattern if present
      if (payload.creatorId && !/^\d+$/.test(payload.creatorId)) {
        message.error('creatorId must contain only digits.');
        return;
      }

      if (selectedEvent) {
        await apiClient.patch(`/api/events/${String(selectedEvent.id)}`, payload);
        message.success('Event updated successfully');
      } else {
        await apiClient.post('/api/events', payload);
        message.success('Event created successfully');
      }
      setIsModalOpen(false);
      fetchEvents();
    } catch (error: any) {
      console.error('Error:', error);
      if (error.response?.data?.errors) {
        // Display detailed validation errors
        error.response.data.errors.forEach((err: any) => {
          message.error(`${err.param}: ${err.msg}`);
        });
      } else {
        message.error(error.response?.data?.message || 'Failed to save the event');
      }
    }
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name', width: 150 },
    { title: 'Location', dataIndex: 'location', key: 'location', width: 150 },
    { title: 'Organizer', dataIndex: 'organizer', key: 'organizer', width: 150 },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
      width: 200,
      render: (text: string) => (text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-'),
    },
    {
      title: 'End Time',
      dataIndex: 'endTime',
      key: 'endTime',
      width: 200,
      render: (text: string) => (text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : '-'),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: 200,
      render: (_: any, record: Event) => (
        <>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
            style={{ marginRight: '8px', width: '80px' }}
          >
            Edit
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this event?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} style={{ width: '80px' }}>
              Delete
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div>
      {/* Filter and Create Event Buttons */}
      <Form layout="inline" onFinish={handleFilter} className="mb-4" style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          {/* Dropdown to select filter type */}
          <Form.Item label="Filter By" name="filterType" style={{ marginRight: '16px' }}>
            <Select
              defaultValue="name"
              style={{ width: 200 }}
              onChange={handleFilterTypeChange}
            >
              <Option value="name">Name</Option>
              <Option value="facultyId">Faculty</Option>
              <Option value="clubTypeId">Club Type</Option>
              <Option value="location">Location</Option>
              <Option value="startTime">Start Time</Option>
              <Option value="endTime">End Time</Option>
            </Select>
          </Form.Item>

          {/* Conditional rendering based on selected filter type */}
          {filterType === 'name' && (
            <Form.Item label="Name" name="name" style={{ marginRight: '16px' }}>
              <Input placeholder="Event Name" />
            </Form.Item>
          )}
          {filterType === 'facultyId' && (
            <Form.Item label="Faculty" name="facultyId" style={{ marginRight: '16px' }}>
              <Select
                placeholder="Select Faculty"
                allowClear
                style={{ width: 200 }}
              >
                {faculties.map((faculty) => (
                  <Option key={faculty.id} value={faculty.id}>
                    {faculty.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {filterType === 'clubTypeId' && (
            <Form.Item label="Club Type" name="clubTypeId" style={{ marginRight: '16px' }}>
              <Select
                placeholder="Select Club Type"
                allowClear
                style={{ width: 200 }}
              >
                {clubTypes.map((club) => (
                  <Option key={club.id} value={club.id}>
                    {club.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {filterType === 'location' && (
            <Form.Item label="Location" name="location" style={{ marginRight: '16px' }}>
              <Select
                placeholder="Select Location"
                allowClear
                style={{ width: 200 }}
              >
                {locations.map((loc: string) => (
                  <Option key={loc} value={loc}>
                    {loc}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {filterType === 'startTime' && (
            <Form.Item label="Start Time" name="startTime" style={{ marginRight: '16px' }}>
              <DatePicker showTime style={{ width: 200 }} />
            </Form.Item>
          )}
          {filterType === 'endTime' && (
            <Form.Item label="End Time" name="endTime" style={{ marginRight: '16px' }}>
              <DatePicker showTime style={{ width: 200 }} />
            </Form.Item>
          )}

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ marginRight: '16px' }}>
              Filter
            </Button>
          </Form.Item>
        </div>

        {/* Create Event Button placed immediately after Filter button */}
        <div>
          <Button type="primary" onClick={handleAdd}>
            Create Event
          </Button>
        </div>
      </Form>

      {/* Events Table */}
      <Spin spinning={loading}>
        <Table
          columns={columns}
          dataSource={events}
          rowKey="id"
          pagination={false}
        />
      </Spin>

      {/* Pagination */}
      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={handleTableChange}
        style={{ marginTop: '16px', textAlign: 'right' }}
      />

      {/* Create/Edit Event Modal */}
      <Modal
        title={selectedEvent ? 'Edit Event' : 'Create Event'}
        open={isModalOpen}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Save"
        cancelText="Cancel"
        width={600}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="name"
            label="Event Name"
            rules={[
              { required: true, message: 'Please enter the event name' },
              { max: 1023, message: 'Name cannot exceed 1023 characters' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="summary"
            label="Event Summary"
            rules={[{ required: true, message: 'Please enter the event summary' }]}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            name="location"
            label="Location"
            rules={[{ max: 255, message: 'Location cannot exceed 255 characters' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="externalLink"
            label="External Link"
            rules={[{ max: 1023, message: 'Link cannot exceed 1023 characters' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="collectRegistration"
            label="Collect Registration"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            name="startTime"
            label="Start Time"
          >
            <DatePicker showTime style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="endTime"
            label="End Time"
          >
            <DatePicker showTime style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            name="description"
            label="Detailed Description"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="tags"
            label="Tags"
            rules={[{ max: 1023, message: 'Tags cannot exceed 1023 characters' }]}
          >
            <Input placeholder="Comma-separated tags" />
          </Form.Item>
          <Form.Item
            name="facultyId"
            label="Faculty ID"
            rules={[{ len: 36, message: 'Faculty ID must be 36 characters' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="clubTypeId"
            label="Club Type ID"
            rules={[{ len: 36, message: 'Club Type ID must be 36 characters' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AdminEventsPage;
