import React from 'react';
import { Table } from 'antd';
import { TableProps } from 'antd/lib/table';

interface ColumnType {
  title: string;
  dataIndex?: string; // Optional dataIndex for standard data columns
  key: string;
  render?: (text: any, record: any) => React.ReactNode; // Optional render function for custom cells
}

interface DataTableProps<T> extends Omit<TableProps<T>, 'pagination'> {
  columns: ColumnType[]; // Array of column definitions
  data: T[]; // Array of data objects to display in the table
  pagination?: {
    total: number;
    current: number;
    pageSize: number;
    simple?: boolean;
    showSizeChanger?: boolean;
    pageSizeOptions?: string[];
    onChange?: (page: number, pageSize?: number) => void;
    onShowSizeChange?: (current: number, size: number) => void;
  };
}

const DataTable = <T extends {}>({ columns, data, pagination, ...rest }: DataTableProps<T>) => {
  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={pagination}
      rowKey="id"
      {...rest}
    />
  );
};

export default DataTable;
