import React, { useEffect, useState } from 'react';

interface TimeRange {
  startDate?: string;
  endDate?: string;
}

interface OrganizerData {
  name: string;
  events: number;
}

interface LocationData {
  name: string;
  events: number;
}

interface Event {
  organizer: string;
  location: string;
  startTime: string;
}

interface EventResponse {
  data: Event[];
}

interface Props {
  timeRange: TimeRange;
}

const OrganizersLocationTable: React.FC<Props> = ({ timeRange }) => {
  const [organizerData, setOrganizerData] = useState<OrganizerData[]>([]);
  const [locationData, setLocationData] = useState<LocationData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authentication token not found');
        }

        const params = new URLSearchParams();
        if (timeRange.startDate) {
          params.append('startTime', `${timeRange.startDate}T00:00:00.000Z`);
        }
        if (timeRange.endDate) {
          params.append('endTime', `${timeRange.endDate}T23:59:59.999Z`);
        }
        params.append('limit', '1000');

        const response = await fetch(`https://api.backend.knowwhatson.com/api/events?${params.toString()}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status}`);
        }

        const { data }: EventResponse = await response.json();

        // Process organizer and location data
        const organizerMap: Record<string, number> = {};
        const locationMap: Record<string, number> = {};

        data.forEach((event) => {
          organizerMap[event.organizer] = (organizerMap[event.organizer] || 0) + 1;
          locationMap[event.location] = (locationMap[event.location] || 0) + 1;
        });

        const topOrganizers = Object.entries(organizerMap)
          .map(([name, events]) => ({ name, events }))
          .sort((a, b) => b.events - a.events)
          .slice(0, 8);

        const topLocations = Object.entries(locationMap)
          .map(([name, events]) => ({ name, events }))
          .sort((a, b) => b.events - a.events)
          .slice(0, 8);

        setOrganizerData(topOrganizers);
        setLocationData(topLocations);
      } catch (error: any) {
        setError(error.message || 'An unexpected error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [timeRange]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-[200px]">
        <p>Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-600">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="bg-white rounded-lg p-4 shadow">
        <h2 className="text-lg font-semibold mb-4">Top Organizers</h2>
        <table className="w-full">
          <thead>
            <tr className="text-gray-600 text-sm">
              <th className="text-left py-2">Organizer</th>
              <th className="text-right">Events</th>
            </tr>
          </thead>
          <tbody>
            {organizerData.map((item, index) => (
              <tr key={index} className="border-t border-gray-200">
                <td className="py-2">{item.name}</td>
                <td className="text-right">{item.events}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="bg-white rounded-lg p-4 shadow">
        <h2 className="text-lg font-semibold mb-4">Top Locations</h2>
        <table className="w-full">
          <thead>
            <tr className="text-gray-600 text-sm">
              <th className="text-left py-2">Location</th>
              <th className="text-right">Events</th>
            </tr>
          </thead>
          <tbody>
            {locationData.map((item, index) => (
              <tr key={index} className="border-t border-gray-200">
                <td className="py-2">{item.name}</td>
                <td className="text-right">{item.events}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrganizersLocationTable;
