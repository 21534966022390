import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

interface Event {
  startTime: string;
}

interface Props {
  timeRange: { startDate?: string; endDate?: string };
}

const EventsTrendLinechart: React.FC<Props> = ({ timeRange }) => {
  const [trendData, setTrendData] = useState<{ month: string; events: number }[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTrendData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const params = new URLSearchParams();

        if (timeRange.startDate) {
          params.append('startTime', `${timeRange.startDate}T00:00:00.000Z`);
        }
        if (timeRange.endDate) {
          params.append('endTime', `${timeRange.endDate}T23:59:59.999Z`);
        }
        params.append('limit', '1000');

        const response = await fetch(`https://api.backend.knowwhatson.com/api/events?${params.toString()}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch events data: ${response.status}`);
        }

        const { data }: { data: Event[] } = await response.json();

        const monthlyData: Record<string, number> = {};
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        months.forEach(month => {
          monthlyData[month] = 0;
        });

        data.forEach(event => {
          const eventDate = new Date(event.startTime);
          const month = months[eventDate.getMonth()];
          monthlyData[month] += 1;
        });

        const trendArray = months.map(month => ({
          month,
          events: monthlyData[month],
        }));

        setTrendData(trendArray);
      } catch (err) {
        console.error('Error fetching trend data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchTrendData();
  }, [timeRange]);

  return (
    <div className="bg-white rounded-lg p-4 shadow">
      <h2 className="text-lg font-semibold mb-4">Events Trend</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={trendData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Line 
              type="monotone" 
              dataKey="events" 
              stroke="#4e79a7"
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default EventsTrendLinechart;
