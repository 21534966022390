import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import { Spin, message } from 'antd';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import apiClient from '../utils/axios';

interface TimeRange {
  startDate?: string;
  endDate?: string;
}

interface User {
  id: string;
  citizenship?: string;
  createdAt: string;
}

interface CountryData {
  country: string;
  countryName: string;
  count: number;
  coordinates: [number, number];
}

const COUNTRY_NAMES: { [key: string]: string } = {
  'CN': 'China',
  'AU': 'Australia',
  'IN': 'India',
  'US': 'United States',
  'CA': 'Canada',
  'UK': 'United Kingdom',
  'SG': 'Singapore',
  'NZ': 'New Zealand',
  'KR': 'South Korea',
  'JP': 'Japan',
  'MY': 'Malaysia',
  'ID': 'Indonesia',
  'VN': 'Vietnam',
  'PK': 'Pakistan',
  'FR': 'France',
  'DE': 'Germany',
  'IT': 'Italy',
  'ES': 'Spain',
  'BR': 'Brazil',
  'MX': 'Mexico'
};

const COUNTRY_COORDINATES: { [key: string]: [number, number] } = {
  'CN': [35.8617, 104.1954],
  'AU': [-25.2744, 133.7751],
  'IN': [20.5937, 78.9629],
  'US': [37.0902, -95.7129],
  'CA': [56.1304, -106.3468],
  'UK': [54.2361, -2.3398],
  'SG': [1.3521, 103.8198],
  'NZ': [-40.9006, 174.8860],
  'KR': [35.9078, 127.7669],
  'JP': [36.2048, 138.2529],
  'MY': [4.2105, 101.9758],
  'ID': [-0.7893, 113.9213],
  'VN': [14.0583, 108.2772],
  'PK': [30.3753, 69.3451],
  'FR': [46.2276, 2.2137],
  'DE': [51.1657, 10.4515],
  'IT': [41.8719, 12.5674],
  'ES': [40.4637, -3.7492],
  'BR': [-14.2350, -51.9253],
  'MX': [23.6345, -102.5528],
  'default': [0, 0]
};

function testColorGradient() {
  const testPoints = [0, 0.25, 0.5, 0.75, 1];
  testPoints.forEach(point => {
    const color = getColor(point);
  });
}

const getColor = (percentage: number) => {
  const lightBlue = [208, 233, 242];
  const darkBlue = [65, 135, 206];
  
  const r = Math.floor(lightBlue[0] + (darkBlue[0] - lightBlue[0]) * percentage);
  const g = Math.floor(lightBlue[1] + (darkBlue[1] - lightBlue[1]) * percentage);
  const b = Math.floor(lightBlue[2] + (darkBlue[2] - lightBlue[2]) * percentage);
  
  return `rgb(${r}, ${g}, ${b})`;
};

const createMarkerIcon = (percentage: number) => {
  const color = getColor(percentage);
  const scale = 0.8 + (percentage * 0.4);
  
  return L.divIcon({
    className: 'custom-pin-marker',
    html: `
      <svg width="${40 * scale}" height="${40 * scale}" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 0C7.58 0 4 3.58 4 8c0 5.76 7.12 15.04 7.42 15.42.16.2.37.29.58.29.2 0 .42-.09.58-.29C12.88 23.04 20 13.76 20 8c0-4.42-3.58-8-8-8zm0 11c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z" 
              fill="${color}"
              stroke="white"
              stroke-width="1"
        />
      </svg>
    `,
    iconSize: [40 * scale, 40 * scale],
    iconAnchor: [20 * scale, 40 * scale],
    tooltipAnchor: [0, -20]
  });
};

const UserDistributionMap: React.FC<{ timeRange?: TimeRange }> = ({ timeRange }) => {
  const [countryData, setCountryData] = useState<CountryData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    testColorGradient();

    const fetchUserDistribution = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        
        const params = new URLSearchParams({
          page: '1',
          limit: '10000',
          sortBy: 'createdAt',
          order: 'desc'
        });

        if (timeRange?.startDate) {
          params.append('startDate', timeRange.startDate);
        }
        
        if (timeRange?.endDate) {
          params.append('endDate', timeRange.endDate);
        }

        const response = await apiClient.get<{ users: User[] }>(`/api/users?${params.toString()}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const citizenshipCounts = response.data.users.reduce((acc, user) => {
          if (user.citizenship) {
            const existingEntry = acc.find(entry => entry.country === user.citizenship);
            if (existingEntry) {
              existingEntry.count += 1;
            } else {
              const coordinates = COUNTRY_COORDINATES[user.citizenship] || COUNTRY_COORDINATES['default'];
              const countryName = COUNTRY_NAMES[user.citizenship] || user.citizenship;
              acc.push({ 
                country: user.citizenship,
                countryName,
                count: 1, 
                coordinates 
              });
            }
          }
          return acc;
        }, [] as CountryData[]);

        setCountryData(citizenshipCounts.sort((a, b) => b.count - a.count));
      } catch (err) {
        setError('Failed to load user distribution');
        message.error('Failed to load user distribution');
      } finally {
        setLoading(false);
      }
    };

    fetchUserDistribution();
  }, [timeRange]);

  if (loading) {
    return (
      <div className="bg-white rounded-lg p-4 shadow flex justify-center items-center min-h-[500px]">
        <Spin size="large" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg p-4 shadow flex justify-center items-center min-h-[500px] text-red-600">
        {error}
      </div>
    );
  }

  const totalUsers = countryData.reduce((sum, curr) => sum + curr.count, 0);
  const maxUsers = Math.max(...countryData.map(c => c.count));

  return (
    <div className="bg-white rounded-lg p-4 shadow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">User Distribution Map</h2>
        <div className="flex items-center gap-6">
          <div className="flex items-center gap-2">
            <span className="text-xs text-gray-600">Lowest</span>
            <div 
              className="h-6 w-32 rounded" 
              style={{
                background: `linear-gradient(to right, ${getColor(0)}, ${getColor(1)})`
              }}
            />
            <span className="text-xs text-gray-600">Highest</span>
          </div>
          <span className="text-sm text-gray-600 border-l pl-4">
            Total Countries: {countryData.length}
          </span>
        </div>
      </div>

      <MapContainer 
        center={[20, 0]} 
        zoom={2} 
        scrollWheelZoom={false} 
        style={{ height: '500px', width: '100%' }}
        className="rounded-lg border border-gray-200"
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {countryData.map((country, index) => {
          const percentage = country.count / maxUsers;
          
          return (
            <Marker 
              key={index} 
              position={country.coordinates} 
              icon={createMarkerIcon(percentage)}
            >
              <Tooltip 
                permanent={false} 
                direction="top"
                className="custom-tooltip"
              >
                <div className="font-sans bg-white rounded-lg shadow-lg p-2 border border-gray-200">
                  <div className="font-bold text-gray-800">{country.countryName}</div>
                  <div className="text-gray-600">Users: {country.count}</div>
                  <div className="text-gray-500 text-sm">
                    ({((country.count / totalUsers) * 100).toFixed(1)}%)
                  </div>
                </div>
              </Tooltip>
            </Marker>
          );
        })}
      </MapContainer>

      <div className="mt-4 overflow-x-auto">
        <table className="w-full text-sm">
          <thead>
            <tr className="border-b">
              <th className="text-left py-2 px-4">Country</th>
              <th className="text-right py-2 px-4">Users</th>
              <th className="text-right py-2 px-4">Percentage</th>
            </tr>
          </thead>
          <tbody>
            {countryData.map((country) => (
              <tr key={country.country} className="border-b hover:bg-gray-50">
                <td className="py-2 px-4">{country.countryName}</td>
                <td className="text-right py-2 px-4">{country.count}</td>
                <td className="text-right py-2 px-4">
                  {((country.count / totalUsers) * 100).toFixed(1)}%
                </td>
              </tr>
            ))}
            <tr className="font-medium bg-gray-50">
              <td className="py-2 px-4">Total</td>
              <td className="text-right py-2 px-4">{totalUsers}</td>
              <td className="text-right py-2 px-4">100.0%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserDistributionMap;